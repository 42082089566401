<template>
  <v-dialog v-model="show" max-width="550px" max-height="551px" persistent>
    <v-card class="px-0 pb-0 pt-0">
      <v-card-title class="px-4">
        <span class="text-h5" :style="'color: #182026;font-weight:500;font-size:20px !important'">{{ titulo }}</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon class="ma-0">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="ma-0 pa-4">
        <v-form ref="form" v-model="valid" lazy-validation class="pa-0 ma-0" id="formIncidence">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                id="idCodigo"
                v-model="incidenceForEdit.code"
                label="Código *"
                required
                maxlength="2"
                minlength="2"
                :hint="codigoHint"
                dense
                outlined
                disabled
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="pt-4 pb-0">
              <v-text-field
                id="idNome"
                v-model="incidenceForEdit.name"
                :rules="[rules.requiredName]"
                label="Nome *"
                hide-details="auto"
                dense
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="pt-4 pb-0">
              <v-list-item class="pb-4 pl-0 pr-0">
                <v-combobox
                  hide-details="auto"
                  v-model="incidenceForEdit.group"
                  :items="groups"
                  label="Grupo"
                  outlined
                  dense
                >
                  <template slot="item" slot-scope="data">
                    <v-tooltip bottom v-if="data.item.length > 62" max-width="250" max-height="56">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{ start_and_end(data.item, 62) }}
                        </span>
                      </template>
                      <span>{{ data.item }}</span>
                    </v-tooltip>
                    <span v-else> {{ start_and_end(data.item, 62) }}</span>
                  </template>
                </v-combobox>
              </v-list-item>
            </v-col>
            <v-col cols="12" class="pt-0 pb-4">
              <v-textarea
                rows="4"
                cols="40"
                minlength="0"
                maxlength="300"
                :counter="300"
                v-model="incidenceForEdit.notes"
                :rules="[rules.requiredJust]"
                required
                hide-details="auto"
                label="Justificativa *"
                dense
                outlined
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-form>
        <div class="text-left pt-8" style="font-size: 14px; color: #575767; font-weight: 400">
          * Campos de preenchimento obrigatório
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="close" class="btn-ag-tertiary" id="btnCancel"> Cancelar</v-btn>
        <v-btn @click="save()" class="btn-ag-primary" id="btnConfirmOredit" :disabled="isEnableSave">
          {{ labelBotao }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import eSocialService from '@/services/eSocialService.js';
import { mapGetters, mapActions } from 'vuex';
import { eventBus } from '../../../../../main.js';
import { required } from 'vuelidate/lib/validators';
import { cloneDeep } from 'lodash';

export default {
  name: 'ModalIncidenceINSSEdit',
  props: ['incidence'],

  data() {
    return {
      incidenceForEdit: {},
      valid: true,
      codigoHint: 'Preencha até 2 números (0-9)',
      groups: [
        'Sem grupo',
        'Base de cálculo das contribuições sociais - Salário de contribuição',
        'Contribuição descontada do segurado sobre salário de contribuição',
        'Outros',
        'Suspensão de incidência sobre salário de contribuição em decorrência de decisão judicial',
      ],
      titulo: 'Editar incidência',
      labelBotao: 'Salvar',
      show: false,
      persistentCodigo: true,

      rules: {
        requiredName: (name) => !!name || 'Preencha um nome',
        requiredJust: (just) => !!just || 'Preencha a justificativa de alteração',
      },
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },
    };
  },
  validations: {
    incidenceForEdit: {
      code: { required },
      name: { required },
      notes: { required },
    },
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
    // incidenceForEdit() {
    //   let localIncidence = cloneDeep(this.incidence);
    //   return localIncidence;
    // },
    isEnableSave() {
      let count = 0;
      if (![undefined, null].includes(this.incidenceForEdit.code)) {
        if (!this.isEmpty(this.incidenceForEdit.code)) {
          ++count;
        }
      }
      if (![undefined, null].includes(this.incidenceForEdit.name)) {
        if (!this.isEmpty(this.incidenceForEdit.name)) {
          ++count;
        }
      }

      if (![undefined, null].includes(this.incidenceForEdit.notes)) {
        if (!this.isEmpty(this.incidenceForEdit.notes)) {
          ++count;
        }
      }

      if (count === 3) {
        return false;
      } else {
        return true;
      }
    },
  },

  created() {
    this.init();
  },

  methods: {
    isEmpty(str) {
      if (str.trim().length === 0) {
        return true;
      } else {
        return false;
      }
    },
    async save() {
      this.$v.incidenceForEdit.$touch();

      if (this.$v.incidenceForEdit.$error || this.$v.incidenceForEdit.$pending) {
        this.$v.$reset();
        this.invalidForm = true;
        return;
      }
      if (this.incidenceForEdit.group === 'Sem grupo') {
        this.incidenceForEdit.group = '';
      }
      let incidenceLocal = {
        code: this.incidenceForEdit.code,
        name: this.incidenceForEdit.name,
        group: this.incidenceForEdit.group,
        user: `${this.userInfo.firstName} ${this.userInfo.lastName}`,
        notes: this.incidenceForEdit.notes,
      };

      const response = await eSocialService.rubricAutomationConfig.incidenceINSSSave(incidenceLocal);
      if (response) {
        this.$emit('refresh');
        this.close();
        this.showToast('Alterações realizadas com sucesso!.', 'success', 78);
      } else {
        this.showToast('Erro ao adicionar incidencia', 'warning', 78);
      }
    },
    codeExist(code) {
      return this.incidences.some((item) => item.code === code);
    },
    init() {
      this.$on('open', this.open);
      this.$on('close', this.close);
    },

    async open() {
      this.incidenceForEdit = cloneDeep(this.incidence);
      this.titulo = 'Editar incidência';
      this.labelBotao = 'Salvar';
      this.show = true;
    },

    close() {
      this.incidenceForEdit.notes = '';
      this.$refs.form.resetValidation();
      this.show = false;
    },
    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },
    start_and_end(str, lengthStr) {
      if (str.length > lengthStr) {
        return str.substr(0, lengthStr) + '...';
      }
      return str;
    },
  },
};
</script>
<style scoped lang="scss">
@import './src/design/components/input.scss';
@import './src/design/components/select.scss';
@import './src/design/components/datepicker.scss';

.v-card__actions {
  padding: 16px !important;
}

::v-deep .v-text-field__details .v-messages {
  text-align: right !important;
}
::v-deep .v-text-field__details .v-messages.theme--light.error--text {
  text-align: left !important;
  background: url('https://api.iconify.design/bxs/error-circle.svg?color=rgba(150, 45, 51, 1)') no-repeat top left !important;
}
</style>
